@import "../sass/variaveis";
@import "../sass/utilities/reset";

@import "../sass/mixins"; //MIXINS
@import "../sass/utilities/animation";

//components
@import "../sass/components/body"; //BODY
@import "../sass/components/menu/menu";
@import "../sass/components/footer";
@import "../sass/components/login";

// STYLE SALEBRAND
@import "../sass/sections/salebrand/salebrand_style";

// PÁGINAS
main {
  overflow: hidden;
}

.body__comming_soon{
  @import "../sass/pages/page_comming_soon";
}

.body__home{
  @import "../sass/pages/page_home";
}


